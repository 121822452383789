import { FC } from "react";
import { IconType } from "react-icons";

interface ContactItemProps{
    Icon:IconType;
    title:string;
    contact:string;
    link:string;
    linkMessage:string;
}
const ContactItem:FC<ContactItemProps> = ({Icon,title,contact,link,linkMessage}) => {
  return (
    <article className="contact__option">
        <Icon className="contact__option-icon"/>
        <h4>{title}</h4>
        <h5>{contact}</h5>
        <a href={link} target='_blank'>{linkMessage}</a>
    </article>
  )
}

export default ContactItem