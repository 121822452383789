export const portfolio_data = {
  portfolios: [
    {
      projectName: "Plantura",
      image: require("../../../assets/images/projects/plantura.png"),
      liveDemoUrl: "https://plantura.garden/app/",
    },
    {
      projectName: "QFX Cinemas",
      image: require("../../../assets/images/projects/qfx.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.exolutus.qfxcinemas",
    },
    {
      projectName: "EPharmacy",
      image: require("../../../assets/images/projects/epharmacy.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.epharmacy_app",
    },
    {
      projectName: "Nabil Gen Alpha",
      image: require("../../../assets/images/projects/nabil.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.amniltech.nabil.genalpha",
    },
    {
      projectName: "Fcube Cinema",
      image: require("../../../assets/images/projects/fcube.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.mobile.fcube",
    },
    {
      projectName: "MidTown Cinema",
      image: require("../../../assets/images/projects/midtown.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.mobile.midtown",
    },
    {
      projectName: "BSR Movies",
      image: require("../../../assets/images/projects/bsr.png"),
      liveDemoUrl:
        "https://play.google.com/store/apps/details?id=com.mobile.bsrmovies",
    },
  ],
};
