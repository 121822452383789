export const experience_data = {
  ExperienceCards: [
    {
      title: "Frontend Development",
      items: [
        {
          framework: "React Native",
          level: "Experienced",
        },
        {
          framework: "React Native Expo",
          level: "Experienced",
        },
        {
          framework: "Android(Java/Kotlin)",
          level: "Experienced",
        },
        {
          framework: "React Js",
          level: "Experienced",
        },
        {
          framework: "HTML",
          level: "Experienced",
        },
        {
          framework: "Tailwind",
          level: "Experienced",
        },
        {
          framework: "CSS",
          level: "Intermediate",
        },
      ],
    },
    {
      title: "Backend Development",
      items: [
        {
          framework: "NodeJs",
          level: "Intermediate",
        },
        {
          framework: "ExpressJs",
          level: "Intermediate",
        },
        {
          framework: "MongoDB",
          level: "Intermediate",
        },
        {
          framework: "MySQL",
          level: "Intermediate",
        },
        {
          framework: "CI/CD",
          level: "Learning",
        },
        {
          framework: "Docker",
          level: "Learning",
        },
        {
          framework: "Firebase",
          level: "Experienced",
        },
        {
          framework: "AWS",
          level: "Learning",
        },
      ],
    },
  ],
};
