import Resume from "../../../assets/files/yuba_resume.pdf";

const CallToAction = () => {
  return (
    <div className="cta">
      <a href={Resume} className="btn" download>
        Download Resume
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default CallToAction;
