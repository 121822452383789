import { useWebsiteData } from "../../services/zustanStore/store";
import "./Portfolio.css";
import PortfolioCard from "./PortfolioCard";

const Portfolio = () => {
  const { portfolio } = useWebsiteData();
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {portfolio?.portfolios.map((item, index) => (
          <PortfolioCard key={"portfolio_card_" + index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
