import { useWebsiteData } from "../../services/zustanStore/store";
import ServiceCard from "./ServiceCard";
import "./Services.css";

const Services = () => {
  const { services } = useWebsiteData();
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        {services?.ServiceCards.map((item, index) => (
          <ServiceCard key={"service_card_" + index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Services;
