import TestimonialCard from "./TestimonialCard";
import "./Testimonials.css";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useWebsiteData } from "../../services/zustanStore/store";

const Testimonials = () => {
  const { testimonials } = useWebsiteData();

  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay
        rewind
      >
        {testimonials?.clientTestimonials.map((item, index) => (
          <SwiperSlide>
            <TestimonialCard key={item.id} {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
