import { FC } from "react"
import {BiCheck} from 'react-icons/bi'

export interface ServiceItemProps{
    description:string
}

const ServiceItem:FC<ServiceItemProps> = ({description}) => {
  return (
    <article className="service__details">
        <ul className="service__list">
            <li>
            <BiCheck className="service__list-icon"/>
            <p>{description}</p>
            </li>
        </ul>
    </article>
  )
}

export default ServiceItem