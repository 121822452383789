import { useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ContactForm = () => {
  const form = useRef<any>();
  const SERVER_ID = process.env.REACT_APP_EMAILJS_SERVER_ID || "";
  const TEMPLETE_ID = process.env.REACT_APP_EMAILJS_TEMPLETE_ID || "";
  const PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || "";

  useEffect(() => {
    emailjs.init(PUBLIC_KEY);
  });

  const sendEmail = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(SERVER_ID, TEMPLETE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        toast.success(
          "Your message is received. I will get back to you soon.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        toast.error(
          "Sorry! Currently we are unable to send message. Try again Later.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
      }
    );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your Full Name" required />
        <input type="text" name="email" placeholder="Your Email" required />
        <textarea
          name="message"
          rows={7}
          placeholder="Your Message"
          required
        ></textarea>
        <button type="submit" className="btn btn-primary">
          Send Message
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default ContactForm;
