import create from 'zustand'
import { ZustanStoreType } from '../../types/zustan'

export const useWebsiteData = create<ZustanStoreType>((set) => ({
    about:null,
    contact:null,
    education:null,
    experience:null,
    footer:null,
    header:null,
    portfolio:null,
    services:null,
    skills:null,
    testimonials:null,
}))

