import { useWebsiteData } from "../../services/zustanStore/store";
import "./Footer.css";

const Footer = () => {
  const { footer } = useWebsiteData();
  return (
    <footer>
      <a href="#" className="footer__logo">
        YUBA
      </a>
      <ul className="permalinks">
        {footer?.Permalinks.map((item, index) => (
          <li>
            <a href={item.link}>{item.title}</a>
          </li>
        ))}
      </ul>
      <div className="footer__copyright">
        <small>&copy; Yuba Raj Oli. All rights reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
