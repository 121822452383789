export const footer_data = {
    Permalinks : [
        {
            id:1001,
            title:'Home',
            link:'#'
        },
        {
            id:1002,
            title:'About',
            link:'#about'
        },
        {
            id:1003,
            title:'Experience',
            link:'#experience'
        },
        {
            id:1004,
            title:'Services',
            link:'#services'
        },
        {
            id:1005,
            title:'Portfolio',
            link:'#portfolio'
        },
        {
            id:1006,
            title:'Testimonials',
            link:'#testimonials'
        },
        {
            id:1007,
            title:'Contact',
            link:'#contact'
        },
    ]
}