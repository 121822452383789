import { FC } from "react";

interface PortfolioCardProps {
  projectName: string;
  image: string;
  gitHubUrl?: string;
  liveDemoUrl?: string;
}

const PortfolioCard: FC<PortfolioCardProps> = ({
  projectName,
  image,
  gitHubUrl,
  liveDemoUrl,
}) => {
  return (
    <div className="portfolio__item">
      <div className="portfolio__item-image">
        <img src={image} alt={projectName} />
      </div>
      <h3>{projectName}</h3>
      <div className="portfolio__item-cta">
        {gitHubUrl && (
          <a
            href={gitHubUrl}
            className="btn"
            target={"_blank"}
            rel="noreferrer"
          >
            GitHub
          </a>
        )}
        {liveDemoUrl && (
          <a
            href={liveDemoUrl}
            className="btn btn-primary"
            target={"_blank"}
            rel="noreferrer"
          >
            Reference Link
          </a>
        )}
      </div>
    </div>
  );
};

export default PortfolioCard;
