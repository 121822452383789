import { useWebsiteData } from "../../services/zustanStore/store";
import "./Experience.css";
import ExperienceCard from "./ExperienceCard";

const Experience = () => {
  const { experience } = useWebsiteData();
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        {experience?.ExperienceCards.map((item, index) => (
          <ExperienceCard key={"experience_card_" + index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Experience;
