import React, { useState } from "react";
import "./Nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BsJournalBookmark } from "react-icons/bs";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  return (
    <nav>
      <abbr title="Home">
        <a
          className={activeNav == "#" ? "active" : ""}
          href="#"
          onClick={() => setActiveNav("#")}
        >
          <AiOutlineHome />
        </a>
      </abbr>
      <abbr title="About Me">
        <a
          className={activeNav == "#about" ? "active" : ""}
          href="#about"
          onClick={() => setActiveNav("#about")}
        >
          <AiOutlineUser />
        </a>
      </abbr>
      <abbr title="My Experience">
        <a
          className={activeNav == "#experience" ? "active" : ""}
          href="#experience"
          onClick={() => setActiveNav("#experience")}
        >
          <BsJournalBookmark />
        </a>
      </abbr>
      <abbr title="Services">
        <a
          className={activeNav == "#services" ? "active" : ""}
          href="#services"
          onClick={() => setActiveNav("#services")}
        >
          <RiServiceLine />
        </a>
      </abbr>
      <abbr title="Contact Me">
        <a
          className={activeNav == "#contact" ? "active" : ""}
          href="#contact"
          onClick={() => setActiveNav("#contact")}
        >
          <BiMessageSquareDetail />
        </a>
      </abbr>
    </nav>
  );
};

export default Nav;
