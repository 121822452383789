import { FC } from "react"
import ServiceItem, { ServiceItemProps } from "./ServiceItem"

export interface ServiceCardProps{
    title:string
    items:ServiceItemProps[]
}
const ServiceCard:FC<ServiceCardProps> = ({title, items}) => {
  return (
    <div className="services__card">
        <div className="services__card-head">
        <h3>{title}</h3>
        </div>
        <div className="services__content">
            {items.map((item,index)=><ServiceItem key={'service_item-'+index} {...item}/>)}
        </div>
    </div>
  )
}

export default ServiceCard