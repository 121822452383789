export const services_data = {
  ServiceCards: [
    {
      title: "Mobile Development",
      items: [
        {
          description:
            "Hybrid Mobile application Development using React Native (Cli and Expo)",
        },
        {
          description:
            "Android Mobile application Development using Kotlin/Java",
        },
        {
          description:
            "Experience with different app development fields e-Commerce, Movies, Home services",
        },
        {
          description:
            "Mobile application planning, engineering, architecturing and deployment",
        },
      ],
    },
    {
      title: "Web Development",
      items: [
        {
          description: "Responsive web application development using React Js",
        },
        { description: "Highly compatible and smooth web application" },
      ],
    },
    {
      title: "Backend Development",
      items: [
        { description: "Backend system development using Node/Express" },
        { description: "SQL (MySql) and NoSQL(MongoDB) database integration" },
        { description: "Deployment to server" },
      ],
    },
  ],
};
