import { FiMail } from "react-icons/fi";
import { RiMessengerLine } from "react-icons/ri";
import { AiOutlineWhatsApp } from "react-icons/ai";

export const contact_data = {
  ContactItems: [
    {
      id: 1,
      title: "Email",
      contact: "",
      link: "mailto:mathewuv8@gmail.com",
      linkMessage: "Send a email",
      Icon: FiMail,
    },
    // {
    //     id:2,
    //     title:'Messenger',
    //     contact:'@yubraj',
    //     link:'https://m.me/yubraj.oli',
    //     linkMessage:'Send a message',
    //     Icon:RiMessengerLine
    // },
    {
      id: 3,
      title: "WhatsApp",
      contact: "",
      link: "https://api.whatsapp.com/send?phone=9779849774795",
      linkMessage: "What's App me",
      Icon: AiOutlineWhatsApp,
    },
  ],
};
