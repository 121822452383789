import { FC } from "react";
interface TestimonialCardProps {
  id: number;
  client: string;
  avatar: string;
  testimonial: string;
  link?: string;
  title?: string;
}
const TestimonialCard: FC<TestimonialCardProps> = ({
  id,
  link,
  client,
  avatar,
  testimonial,
  title,
}) => {
  return (
    <article className="testimonial__card">
      <a target="_blank" href={link} rel="noreferrer">
        <div className="client__avatar">
          <img src={avatar} alt={client} />
        </div>
        <h5>{client}</h5>
        <h6>* {title} *</h6>
      </a>
      <small className="client__review">"{testimonial}"</small>
    </article>
  );
};

export default TestimonialCard;
