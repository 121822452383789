export const header_data = {
  display_picture: require("../../../assets/images/header-me.png"),
  working_as: "Senior Software Engineer",
  socialLinks: {
    FACEBOOK: "https://facebook.com",
    INSTAGRAM: "https://instagram.com",
    X: "https://x.com/mathew_uv",
    LINKEDIN: "https://www.linkedin.com/in/yuba-oli/",
    GITHUB: "https://github.com/yubarajoli77",
    PLAYSTORE: "https://play.google.com/store/apps",
  },
};
