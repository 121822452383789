import { FC } from 'react';
import { IconType } from 'react-icons';
import {VscFolderLibrary} from 'react-icons/vsc';

interface CardProps {
    title:string;
    description:string;
    Icon: IconType
}
const Card:FC<CardProps> = ({title, description, Icon}) => {
  return (
    <article className="about__card">
        <Icon/>
        <h5>{title}</h5>
        <small>{description}</small>
    </article>
  )
}

export default Card