import { useWebsiteData } from "../../services/zustanStore/store";
import "./Contact.css";
import ContactForm from "./ContactForm";
import ContactItem from "./ContactItem";

export const Contact = () => {
  const { contact } = useWebsiteData();
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          {contact?.ContactItems.map((item, index) => (
            <ContactItem key={item.id} {...item} />
          ))}
        </div>
        <ContactForm />
      </div>
    </section>
  );
};
