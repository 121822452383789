import "./About.css";
import Card from "./Card";
import { useWebsiteData } from "../../services/zustanStore/store";

export const About = () => {
  const about = useWebsiteData((state) => state.about);
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={about?.image} alt="About" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            {about?.aboutMeCards.map((item: any, index: number) => (
              <Card {...item} />
            ))}
          </div>
          <p>{about?.description}</p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};
