export const testimonials_data = {
  clientTestimonials: [
    {
      id: 2,
      avatar: require("../../../assets/images/testimonials/yubaraj.jpeg"),
      link: "https://www.linkedin.com/in/yubarajkalathoki/",
      client: "Yuba Raj Kalathoki",
      title: "Lead Software Engineer at ZurelSoft",
      testimonial:
        "I am really impressed by the quality of services I received from you. You were right on schedule, were professional and courteous in dealings, and delivered well before time. Also I love your enthusiasm and open nature to adapt new technology.",
    },
    {
      id: 3,
      avatar: require("../../../assets/images/testimonials/isen.jpeg"),
      link: "https://www.linkedin.com/in/isen-majennt/",
      client: "Isen Majennt",
      title: "Project Director at Tech+Art, Singapore",
      testimonial:
        "Yuba has been a great asset to my mobile app development process. He is a meticulous team member who sees the big picture and makes great suggestions to enhance user experience while adhering to beautiful, high-quality coding. I hope to work alongside him again in future mobile app endeavours.",
    },
    {
      id: 5,
      avatar: require("../../../assets/images/testimonials/navraj.jpeg"),
      client: "Nav Raj Bhattarai",
      title: "Senior UI/UX Designer at Amazing Digital",
      link: "https://www.linkedin.com/in/iamnavraj/",
      testimonial:
        "Yuba Raj is a very good and skilled mobile application developer. He is very professional at his work. He has a deep technical knowledge and experience with advance technologies. Apart from technical aspects, he is a very good human being and close friend I made during the time with him. Best wishes for him forever.",
    },
    {
      id: 6,
      avatar: require("../../../assets/images/testimonials/sujan.jpeg"),
      client: "Sujan Shrestha",
      title: "Project Lead at AMNIL Technologies",
      link: "https://www.linkedin.com/in/sujanshr/",
      testimonial:
        "I have worked with Yubraj for the past year and it has been a pleasure. He was an integral part of our team for various clients.  I would recommend Yubraj  for any position and would welcome him back to the team.",
    },
    {
      id: 7,
      avatar: require("../../../assets/images/testimonials/mandip.jpeg"),
      client: "Mandip Giri",
      title: "Software Engineer at CloudFactory",
      link: "https://www.linkedin.com/in/mandip-giri-02166080/",
      testimonial:
        "Working with Yuba has been wonderful. His dedication and hard working attitude is one of the many wonderful attributes to describe Yuba & let's not forget working with is always wonderful and couldn't have asked for a better colleague.",
    },
    {
      id: 8,
      avatar: require("../../../assets/images/testimonials/pradeep.jpeg"),
      client: "Pradeep Pandey",
      title: "QA Test Automation Engineer at Plantura GmbH",
      link: "https://www.linkedin.com/in/pradeipp/",
      testimonial:
        "Me and Yuba joined our company together. He was very friendly right from the get go and as time went by I got to witness how efficient, smart, helpful and kind he is. He's saved the day(both at work and outside) a few too many times and has always encouraged me to learn react-native and is always there for me. Yuba has been a true blessing.",
    },
  ],
};
