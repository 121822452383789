import { useCallback, useEffect } from "react";
import { getApiClient } from "./webSite/services/api";
import { useWebsiteData } from "./webSite/services/zustanStore/store";
import WebSite from "./webSite/WebSite";

function EntryPoint() {
  const apiClient = getApiClient();

  const updateWebsiteData = useCallback(() => {
    useWebsiteData.setState({
      about: apiClient.getAbout(),
      contact: apiClient.getContact(),
      education: apiClient.getEducation(),
      experience: apiClient.getExperience(),
      footer: apiClient.getFooter(),
      header: apiClient.getHeader(),
      portfolio: apiClient.getPortfolio(),
      services: apiClient.getServices(),
      skills: apiClient.getSkills(),
      testimonials: apiClient.getTestimonials(),
    });
  }, [apiClient]);

  useEffect(() => {
    updateWebsiteData();
  });

  return <WebSite />;
}

export default EntryPoint;
