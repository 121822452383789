import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';

export const about_data = {
    image: require('../../../assets/images/about-me.jpg'),
    description: 'I worked on different software systems such as ecommerce, cinema, tracking and reporting, home services, banking and insurance and many more. And was involved in different phases from planning to deployment. I am more experienced and feel comfortable on mobile application development but can also do MERN stuff. So Yuba is experienced, professional and enthusiastic software engineer. Interested!!! For more detail you can download my resume or always contact me.',
    aboutMeCards : [
        {
            title:'Experience',
            description:'4+ Years of Work',
            Icon: FaAward
        },
        {
            title:'Clients',
            description:'8+ Worldwide',
            Icon: FiUsers
        },
        {
            title:'Projects',
            description:'10+ Completed',
            Icon: VscFolderLibrary
        },
    
    ]
}