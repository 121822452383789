import { BsLinkedin, BsGithub } from "react-icons/bs";
import { SiGoogleplay } from "react-icons/si";
import { useWebsiteData } from "../../services/zustanStore/store";

const HeaderSocial = () => {
  const { header } = useWebsiteData();
  return (
    <div className="header__social">
      <a href={header?.socialLinks.LINKEDIN} target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href={header?.socialLinks.GITHUB} target="_blank" rel="noreferrer">
        <BsGithub />
      </a>
      <a href={header?.socialLinks.PLAYSTORE} target="_blank" rel="noreferrer">
        <SiGoogleplay />
      </a>
    </div>
  );
};

export default HeaderSocial;
