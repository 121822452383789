import { FC } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";

export interface ExperienceItemProps {
  framework: string;
  level: string;
}
const ExperienceItem: FC<ExperienceItemProps> = ({ framework, level }) => {
  return (
    <article className="experience__details">
      <BsFillPatchCheckFill className="experience__details-icon" />
      <div>
        <h4>{framework}</h4>
        <small className="text-light">{level}</small>
      </div>
    </article>
  );
};

export default ExperienceItem;
