import { about_data } from "../data/about";
import { contact_data } from "../data/contact";
import { education_data } from "../data/education";
import { experience_data } from "../data/experience";
import { footer_data } from "../data/footer";
import { header_data } from "../data/header";
import { portfolio_data } from "../data/portfolio";
import { services_data } from "../data/services";
import { skills_data } from "../data/skills";
import { testimonials_data } from "../data/testimonials";

export class ApiClient {
   getAbout(){
    return about_data;
   }
   getContact(){
    return contact_data
   }
   getEducation(){
    return education_data

   }
   getExperience(){
    return experience_data

   }
   getFooter(){
    return footer_data 

   }
   getHeader(){
    return header_data

   }
   getPortfolio(){
    return portfolio_data
   }
   getServices(){
    return services_data

   }
   getSkills(){
    return skills_data

   }
   getTestimonials(){
    return testimonials_data

   }
}