import { FC } from "react";
import ExperienceItem, { ExperienceItemProps } from "./ExperienceItem";

interface ExperienceCardProps{
    title:string;
    items:ExperienceItemProps[]
}
const ExperienceCard:FC<ExperienceCardProps> = ({title, items}) => {
  return (
    <div className="experience__card">
        <h3>{title}</h3>
        <div className="experience__content">
        {items.map((item, index)=><ExperienceItem key={'experience_item_'+index} {...item}/>)}
        </div>
    </div>
  )
}

export default ExperienceCard